<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-8 px-0">
              <div class="col-3 pr-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="$t('mealsystem.placeholder.search')"
                    v-model="search"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchMeals">
                {{ $t("button.filter") }}
              </b-button>
            </div>
            <div class="card-toolbar">
              <b-button
                variant="success"
                v-b-modal.modal-meal-edit
                @click="setNewMeal()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("mealsystem.meals.button.create") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th>{{ $t("mealsystem.meals.label.name") }}</th>
                <th width="90"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(meal, index) in meals.data" :key="index">
                <td>
                  <span class="font-weight-bolder">{{ meal.name }}</span>
                </td>

                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="$t('mealsystem.meals.title.delete_meal')"
                    v-b-modal.modal-meal-delete
                    @click="setMeal(meal)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="$t('mealsystem.meals.title.edit_meal')"
                    v-b-modal.modal-meal-edit
                    @click="setMealDetail(meal)"
                    ><i class="ki ki-gear icon-md"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="meals.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal
      id="modal-meal-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.meals.title.confirm_delete_recipe_title')"
    >
      <span
        v-html="$t('mealsystem.meals.title.confirm_delete_recipe_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteMeal(selectedMeal)">
          {{ $t("mealsystem.meals.button.delete_meal") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-meal-edit"
      :okTitle="$t('button.save')"
      okVariant="success"
      size="lg"
      :title="$t('mealsystem.meals.title.edit_meal')"
    >
      <h3>{{ selectedMeal.name }}</h3>

      <ValidationObserver ref="form">
        <b-tabs card active-nav-item-class="bg-primary text-white">
          <b-tab :title="$t('mealsystem.meals.title.tab_main_info')">
            <div class="row">
              <div class="col-6">
                <ValidationProvider
                  name="name"
                  rules="required|min:2"
                  v-slot="{ errors }"
                >
                  <span class="text-muted mb-2 d-block">{{
                    $t("mealsystem.meals.label.name")
                  }}</span>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('mealsystem.meals.placeholder.name')"
                    v-model="selectedMeal.name"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('mealsystem.meals.title.tab_assigned_recipes')" v-if="selectedMeal.meal_id">
            <div class="row">
              <div class="col-3 form-group">
                <label class="col-form-label text-success font-weight-bolder">{{
                  $t("mealsystem.meals.title.add_receipe")
                }}</label>
              </div>
              <div class="col-6">
                <vs-select
                  v-if="recipes"
                  :options="recipes"
                  :placeholder="$t('mealsystem.placeholder.search_recipe')"
                  label="name"
                  :reduce="(recipes) => recipes.recipe_id"
                  v-model="newRecipeSelect"
                />
              </div>
              <div class="col-3">
                <b-button variant="success" @click="addNewRecipe()">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.meals.button.create_raw_recipe") }}
                </b-button>
              </div>
            </div>

            <table
              class="table"
              v-if="selectedMeal.recipes && selectedMeal.recipes.length > 0"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("mealsystem.meals.label.recipes") }}
                  </th>

                  <th width="40"></th>
                </tr>
              </thead>

              <tr v-for="(recipe, index) in selectedMeal.recipes" :key="index">
                <td class="form-group">
                  <label class="col-form-label">{{ recipe.name }}</label>
                </td>
                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    @click="deleteRecipe(recipe)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                </td>
              </tr>
            </table>
            <div class="py-20 text-muted text-center" v-else>
              {{ $t("mealsystem.meals.title.no_receips_in_recipe") }}
            </div>
          </b-tab>
        </b-tabs>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateMeal(selectedMeal)">
          {{ $t("mealsystem.meals.button.save_meal") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Pagination from "@/view/content/Pagination";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      meals: null,
      loading: false,
      page: 1,
      search: "",
      selectedMeal: {},
      recipes: null,
      newRecipeSelect: null,
    };
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.meals_list") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.meals"),
        subtitle: this.$t("mealsystem.meta.meals_list"),
      },
    ]);
  },
  components: {
    Pagination,
  },
  beforeMount() {
    this.getMeals();
    this.getRecipes();
  },
  methods: {
    getMeals() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/meals?page=" +
          this.page +
          "&perpage=" +
          this.perpage +
          "&search=" +
          this.search
      ).then((response) => {
        this.meals = response.data;
        this.loading = false;
      });
    },
    setNewMeal() {
      this.selectedMeal = {};
    },
    setMeal(meal) {
      this.selectedMeal = meal;
    },
    setMealDetail(meal) {
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/meals/" + meal.meal_id).then(
        (response) => {
          this.selectedMeal = response.data.data;
        }
      );
    },
    deleteMeal(meal) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost("/meal-system/meals/" + meal.meal_id + "/delete")
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-meal-delete");
          this.getMeals();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    getRecipes() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/recipes").then((response) => {
        this.recipes = response.data.data;
        this.loading = false;
      });
    },
    addNewRecipe() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/meals/" + this.selectedMeal.meal_id + "/recipes",
        {
          recipe_id: this.newRecipeSelect,
        }
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/meals/" + this.selectedMeal.meal_id
          ).then((response) => {
            this.selectedMeal = response.data.data;
            this.newRecipeSelect = null;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    deleteRecipe(recipe) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/meals/recipes/" + recipe.pivot.meal_recipe_id + "/delete"
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/meals/" + this.selectedMeal.meal_id
          ).then((response) => {
            this.selectedMeal = response.data.data;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    removeFilters() {
      this.page = 1;
      this.search = "";
      this.getMeals();
    },
    searchMeals() {
      this.page = 1;
      this.getMeals();
      this.mealSearch = {
        search: this.search,
      };
    },
    updateMeal(meal) {
      this.loading = true;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        }

        if (meal.meal_id) {
          this.endpoint = "/meal-system/meals/" + meal.meal_id + "/update";
        } else {
          this.endpoint = "/meal-system/meals";
        }

        ApiService.setHeader();
        ApiService.apiPost(this.endpoint, {
          name: meal.name,
        })
          .then(() => {
            this.loading = false;
            this.$bvModal.hide("modal-meal-edit");
            this.getMeals();
          })
          .catch((error) => {
            checkErrors(error);
          });
      });
    },
  },
};
</script>